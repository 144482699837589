import styled from 'styled-components';



export const AboutContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};
    // padding: 60px 20px; // Added padding for mobile responsiveness

    @media screen and (max-width: 768px) {
        padding: 80px 20px; // Adjusted padding for smaller devices
    }
`;

export const AboutWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto; // Centered with margin shorthand
    padding: 0 60px;
    justify-content: center;
     @media screen and (max-width: 568px) {
           padding: 0 0px;

    }
`;

export const AboutRow = styled.div`
    display: grid;
    // grid-auto-columns: minmax(auto, 1fr);
    // align-items: center;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        grid-template-areas: 
            'col1'
            'col2'; // Stack images on smaller screens
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #01bf71;
    font-size: 25px;
    line-height: 16px;
    font-weight: 800;
    margin-top: 60px;
    letter-spacing: 1px; // Fixed typo from '1.px' to '1px'
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 1.2;
    font-weight: 600;

    @media screen and (max-width: 480px) {
        font-size: 20px; // Adjust font size for very small screens
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px; // Fixed typo from 'margin-bottm' to 'margin-bottom'
    font-size: 16px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: auto; // Allow height to adjust automatically
`;

export const ImgWrapLogo = styled.div`
    max-width: 555px;
    height: auto; // Allow height to adjust automatically
    margin-left: 20px;

    @media screen and (max-width: 768px) {
        margin-left: 0; // Remove margin on smaller devices
        text-align: center; // Center content for mobile
    }
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 20px 0;
    padding-right: 0;
    object-fit: cover; // Ensures the image covers the area well
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover; // Ensures the video covers the area well
    background: #232a34;
`;