import React, { useState } from 'react'
import {
    BannerContainer,
    BannerBg,
    VideoBg,
    ArrowForward,
    BannerContent,
    BannerP,
    BannerTitle,
    BannerBtnWrapper,
    ArrowRight
} from './BannerElements'
import { Button } from '../../Utilities/ButtonElements'
import Video from '../../Videos/video.mp4'

const Hero = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <>
            <BannerContainer>
                <BannerBg>
                    <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
                </BannerBg>
                <BannerContent>
                    <BannerTitle>
                        {/* Developing next-generation AI-driven applications at scale. */}
                        {/* Building Bootstrapped AI driven Startups & Providing Strategic Consulting */}
                        We Build Bootstrapped AI-Driven Startups and provide Strategic Consulting Services
                    </BannerTitle>
                    <BannerBtnWrapper>
                        <Button
                            to="portfolio"
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            primary="true"
                            dark="true"
                        >
                            Portfolio  {hover ? <ArrowForward /> : <ArrowRight />}
                        </Button>
                    </BannerBtnWrapper>
                </BannerContent>
            </BannerContainer>
        </>
    )
}

export default Hero;
