import React from 'react'
import {
    AboutContainer,
    AboutWrapper,
    AboutRow,
    BtnWrap,
    Column1,
    Column2,
    Heading,
    ImgWrap,
    Subtitle,
    TextWrapper,
    TopLine,
    Img,
    ImgWrapLogo,
    VideoBg
} from '../Founder/FounderElements'

import { Button } from '../../Utilities/ButtonElements';
import { FaLinkedin } from 'react-icons/fa';


const InfoSection = ({
    lightBg,
    imgStart,
    topLine,
    lightText,
    darkText,
    headline,
    description,
    buttonLabel,
    alt,
    img,
    id,
    primary,
    dark,
    dark2
}) => {

    return (
        <>
            <AboutContainer lightBg={lightBg} id='mission'>
                <AboutWrapper>
                    <AboutRow imgStart={imgStart}>
                        <Column1>

                            <ImgWrapLogo>
                                <Img src={img} alt={alt} />
                            </ImgWrapLogo>
                        </Column1>
                        <Column2>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description}</Subtitle>
                                <div className="mb-6 p-4 rounded-lg shadow-md">
                                    <p className="text-white text-md mb-2">
                                        - Kaleb Dufera, Founder of PZMOO LLC
                                  
                                    <a
                                        href="https://www.linkedin.com/in/kaleb-dufera-8ba12998/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{color:"white"}}
                                    >
                                        <FaLinkedin style={{paddingLeft: "6px"}}/>
                                       <span> LinkedIn</span> 
                                    </a>
                                    </p>
                                </div>
                            </TextWrapper>
                        </Column2>
                    </AboutRow>
                </AboutWrapper>
            </AboutContainer>


        </>
    )
}

export default InfoSection
