import React from 'react'
import {
    ServicesContainer,
    ServicesCard,
    ServicesH1,
    ServicesH2,
    ServicesP,
    ServicesWrapper,
    ServicesIcon,
    ServicesH2Reg,
    Images
} from './ServiceElements'


import Icon1 from '../../Images/logo_tole.png'
import Icon2 from '../../Images/stealth.png'
import Icon3 from '../../Images/stealth.png'
import Icon4 from '../../Images/stealth.png'
import Icon5 from '../../Images/stealth.png'
import Icon6 from '../../Images/stealth.png'




import Img1 from '../../Images/ssa.png'
import Img2 from '../../Images/uscis.png'
import Img3 from '../../Images/ventera1.jpg'
import Img4 from '../../Images/cms.png'
import Img5 from '../../Images/drupal.jpeg'


const images = [
    Img1,
    Img2,
    Img4,
    Img3,
    Img5,
];

const stylesX = {
    container: {
        marginTop: "80px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '5px',
        overflow: 'hidden',
        flexWrap: 'wrap', // Allow wrapping for mobile
    },
    image: {
        width: '100px', // Default size
        height: '100px',
        borderRadius: '20%', // Circular shape
        margin: '0 30px', // Default spacing
        opacity: 0.7, // Opacity for images
    },
};

// Use a simple method to apply mobile styles based on window width
const getResponsiveStyles = () => {
    if (window.innerWidth <= 600) { // Mobile breakpoint
        return {
            container: {
                padding: '10px', // Reduced padding on mobile
            },
            image: {
                width: '70px', // Smaller size for mobile
                height: '70px',
                margin: '0 15px', // Reduced margin on mobile
            },
        };
    }
    return stylesX; // Return default styles if not mobile
};

const responsiveStyles = getResponsiveStyles();

const style = {
    color: 'black',
    backgroundColor: '#f7e174',
    padding: '7px',
    borderRadius: '5px',
    textAlign: 'center',
    marginBottom: '15px',
    fontSize: '15px'
};
const ButtonStyles = {
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: 'black',
        color: '#ffffff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};


const Services = () => {
    const handleClick = () => {
        window.open('https://www.toleai.com/', '_blank');
    };

    return (
        <>
            <ServicesContainer id="portfolio">
                <ServicesH1>Portfolio </ServicesH1>
                <ServicesWrapper>
                    <ServicesCard>

                        <ServicesIcon src={Icon1} />
                        <ServicesH2Reg>ToleAI</ServicesH2Reg>
                        <ServicesP>
                            ToleAI offers customizable and shareable AI agents that can converse, analyze images and notes, code, generate images, solve problems, and handle specific tasks across various subjects, documents, and datasets.
                        </ServicesP>
                        {/* <p style={style}>Beta</p> */}

                        <button onClick={handleClick} style={ButtonStyles.button}>
                            Explore
                        </button>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon2} />
                        <ServicesH2>KeyoJobs.ai</ServicesH2>
                        {/* <ServicesP>
                            We develop custom software solutions utilizing the latest
                            technologies to meet unique business needs.
                            Our software solutions are secure, scalable, and resilient,
                            offering a tailored service with personalized attention.
                        </ServicesP> */}
                        <p style={style}>Stealth Mode</p>
                    </ServicesCard>
                    
                    <ServicesCard>
                        <ServicesIcon src={Icon3} />
                        <ServicesH2>Lembo.ai</ServicesH2>
                        {/* <ServicesP>
                            We assist startups in quickly creating MVPs.
                            Our team utilizes agile development
                            methods and proven tools including ReactJS,
                            React Native, NodeJS and SpringBoot to bring clients'
                            ideas to life.
                        </ServicesP> */}
                        <p style={style}>Stealth Mode</p>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon4} />
                        <ServicesH2>CodeX.ai</ServicesH2>
                        {/* <ServicesP>
                            we provide cloud and infrastructure services to businesses, enabling
                            them to manage their resources with efficiency and cost savings.
                            We specialize in innovative solutions such as cloud hosting,
                            virtualization, storage, and disaster recovery.
                        </ServicesP> */}
                        <p style={style}>Stealth Mode</p>

                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon5} />
                        <ServicesH2>Founders Hub </ServicesH2>
                        {/* <ServicesP>
                            Our team specializes in AI and ML technologies and data science
                            services that provide businesses with actionable insights and
                            predictive models for a wide range of applications. We customize
                            our solutions to fit each customer's unique needs.
                        </ServicesP> */}
                        <p style={style}>Stealth Mode</p>

                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon6} />
                        <ServicesH2>MerchMind.ai</ServicesH2>
                        {/* <ServicesP>
                            We offer tailored solutions to organizations of all sizes and industries,
                            to improve performance, reduce costs & optimize customer experience.
                            Our services include process mapping, metrics development, operational &
                            technology assessments, and process improvement.
                        </ServicesP> */}
                        <p style={style}>Stealth Mode</p>
                    </ServicesCard>
                </ServicesWrapper>
            </ServicesContainer>

        </>
    )
}

export default Services


