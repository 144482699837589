import styled from 'styled-components'

export const AboutContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};
    @media screen and (max-width: 640px) {
        padding: 80px 0; // Adjust padding for mobile
    }
`;

export const AboutWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    margin: 0 auto; 
    justify-content: center;
    @media screen and (max-width: 640px) {
        padding: 0 20px; // Adjust padding for mobile
    }
`;

export const AboutRow = styled.div`
    display: grid;
    margin-bottom:50px;
    margin-top:150px;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    @media screen and (max-width: 640px) {
        grid-template-areas: 'col1' 'col2'; // Stack the columns on small screens
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 640px;
    padding-top: 0;
    padding-bottom: 60px;
    @media screen and (max-width: 640px) {
        padding-bottom: 20px; // Less padding on mobile
    }
`;

export const TopLine = styled.p`
    color: #01bf71;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 800;
    padding-bottom: 20px;
    margin-bottom: 16px;
    margin-top: 30px;
    @media screen and (max-width: 640px) {
        margin-top: 10px; // Adjust for mobile
    }
`;

export const Heading = styled.h1`
    color: #000;
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 1.2;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};
    @media screen and (max-width: 640px) {
        font-size: 18px; // Smaller font on mobile
    }
`;

export const Subtitle = styled.p`
  max-width: 540px;
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
    @media screen and (max-width: 640px) {
        font-size: 14px; // Smaller font on mobile
    }
`;

export const ImgWrapLogo = styled.div`
    max-width: 555px;
    height: 100%;
    display: flex;
    justify-content: center; // Center the image
`;

export const Img = styled.img`
    width: 100%;
    margin-top:-50px;
    height: auto;
    border-radius: 100rem; /* Adjusts the corners to be rounded */
    padding: 50px; /* Adds padding around the image */
    background-color: #000; /* Light gray background color */
`;