import { Formik } from "formik";
import { Box } from '@mui/material'
import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Stack } from '@mui/material';
import { Link } from "react-router-dom"
import axios from 'axios';
import { styled } from '@mui/material/styles';

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    BtnWrap,
    Column1,
    Column2,
    Heading,
    ImgWrap,
    Subtitle,
    TextWrapper,
    TopLine,
    Img,
    ImgWrapLogo,
    SuccessMessage,
    ErrorMessage
} from './InfoElements'


// import { Button } from '../../Utilities/ButtonElements'
import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';


const InfoSection = ({
    lightBg,
    imgStart,
    topLine,
    lightText,
    darkText,
    headline,
    description,
    buttonLabel,
    alt,
    img,
    img1,
    img2,
    img3,
    img4,
    id,
    primary,
    dark,
    dark2
}) => {


    const submitHandler = (values) => {
        let body = {
            first_name: values.firstName,
            last_name: values.lastName,
            company_name: values.companyName
        }
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [messageSentError, setMessageSentError] = useState(false);


    const OrangeButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#FF5722'),
        backgroundColor: 'black',
        border: '1px solid green',
        '&:hover': {
            backgroundColor: '#E64A19',
            border: '1px solid #E64A19',
        },
    }));


    function handleSubmit(event) {
        event.preventDefault();

        let body = {
            name: name,
            email: email,
            message: message
        }
        sendContactInfo(body).then((sent) => {
            if (sent) {
                setMessageSent(true);
                setTimeout(function () {
                    setName('');
                    setEmail('');
                    setMessage('');
                    setMessageSent(false);

                }, 4000);
            } else {
                setMessageSentError(true);
                setTimeout(function () {
                    setName('');
                    setEmail('');
                    setMessage('');
                    setMessageSentError(false);
                }, 4000);
            }
        })
    }

    const useStyles = makeStyles((theme) => ({
        input: {
            background: "rgb(232, 241, 250)"
        }
    }));
    const classes = useStyles();

    return (
        <>
            <InfoContainer lightBg={lightBg} id='contact'>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                {/* <TopLine>Investors</TopLine> */}

                                <Subtitle darkText={darkText}>{description}</Subtitle><br />
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            {messageSent ?
                                <SuccessMessage darkText={darkText}>
                                    Thanks for contacting us! We'll be in touch soon.
                                </SuccessMessage> : ""

                            }
                            {messageSentError ?
                                <ErrorMessage darkText={darkText}>
                                    Sorry, there was an error. Please try again.
                                </ErrorMessage> : ""
                            }

                            {!messageSent && !messageSentError ?
                                <React.Fragment>
                                    <form onSubmit={handleSubmit} action={<Link to="/login" />}>
                                        <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                                            <TextField
                                                type="text"
                                                variant='outlined'
                                                InputProps={{ className: classes.input }}
                                                color='success'
                                                label="Name"
                                                onChange={e => setName(e.target.value)}
                                                value={name}
                                                fullWidth
                                                required
                                            />
                                        </Stack>
                                        <TextField
                                            type="email"
                                            variant='outlined'
                                            InputProps={{ className: classes.input }}
                                            color='success'
                                            label="Email"
                                            onChange={e => setEmail(e.target.value)}
                                            value={email}
                                            fullWidth
                                            required
                                            sx={{ mb: 4 }}
                                        />
                                        <TextField
                                            type="text"
                                            InputProps={{ className: classes.input }}

                                            inputProps={{
                                                style: {
                                                    height: "70px",
                                                },
                                            }}
                                            multiline
                                            color='success'
                                            variant='outlined'
                                            label="Message"
                                            onChange={e => setMessage(e.target.value)}
                                            value={message}
                                            fullWidth
                                            required
                                            sx={{ mb: 4 }}
                                        />
                                        <OrangeButton
                                            variant="outlined"
                                            type="submit"
                                            size="large">
                                            Send
                                        </OrangeButton>
                                    </form>
                                </React.Fragment> : ""
                            }
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

async function sendContactInfo(contactInfo) {

    const config = {
        headers: {
            x_auth: process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json'
        }
    };
    return axios.post('https://pzmoov2.herokuapp.com/email', contactInfo, config).then((resp) => {
        if (resp.status === 200) {
            return true;
        } else {
            return false;
        }

    }).catch((err) => {
        return false;
    })
}

export default InfoSection


